import SmoothScroll from "smooth-scroll";

var scroll = new SmoothScroll('a[href*="#"]', {
  speed: 500,
  speedAsDuration: true,
});

// ナビゲーションの操作
const navigationToggleButton = document.querySelector(".header__navigation-toggle");
const navigationToggleButtonTxt = document.querySelector(".header__navigation-toggle p");
const spNavigation = document.querySelector(".header__sp-navigation");

navigationToggleButton.addEventListener("click", toggleNavigation);
spNavigation.addEventListener("click", toggleNavigation);

function toggleNavigation() {
  if (!navigationToggleButton.classList.contains("active")) {
    navigationToggleButton.classList.add("active");
    spNavigation.classList.add("active");
    navigationToggleButtonTxt.innerHTML = "CLOSE";
  } else {
    navigationToggleButton.classList.remove("active");
    spNavigation.classList.remove("active");
    navigationToggleButtonTxt.innerHTML = "MENU";
  }
}
